<template>
  <v-expansion-panel-header color="blue-grey lighten-5">
    <h3>{{label}}</h3>
    <span v-if="caption">{{caption}}</span>
    <v-spacer/>
    
    <template v-slot:actions>
      <v-icon color="primary">
        $expand
      </v-icon>
    </template>
  </v-expansion-panel-header>
</template>
<script>
export default {
  props: {
    label: String,
    caption: String,
  },
}
</script>
